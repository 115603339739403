<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset
            id="information"
            :title="$t('Basic Information')"
          >
            <b-form-group
              :label="`*` + $t('Full name') + `:`"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Full name"
                rules="required"
              >
                <b-form-input
                  v-model="model.fullname"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Full name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="`*` + $t('Content') + `:`"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Content"
                rules="required"
              >
                <b-form-textarea
                  v-model="model.content"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Content')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="`*` + $t('Rating') + `:`"
              label-cols-md="12"
            >
              <b-form-rating
                v-model="model.rating"
                no-border
                stars="5"
                inline
                variant="warning"
              />
            </b-form-group>
            <label>Sản phẩm được thêm comment ảo</label>
            <div
              v-for="(items, index) in model.comment"
              :key="index"
              class="mb-2"
            >
              <b-form-input
                v-model="items.name_product"
                readonly
              />
            </div>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset
              id="status"
              :title="$t('Status')"
            >
              <hr>
              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  @click.prevent="validationForm"
                >
                  {{ $t('Update') }}
                </b-button>
              </div>
            </Fieldset>
            <Fieldset
              id="avatar"
              :title="$t('Avatar')"
            >
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormRating,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormRating,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {},
      activeOptions: Config.activeOptions,
    }
  },
  created() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/customer_fake/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              fullname: this.model.fullname,
              content: this.model.content,
              rating: Number(this.model.rating),
            }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/customer_fake/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Customer Fake success',
                  },
                })
                this.$router.push('/customer-fake/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
